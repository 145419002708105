import React, {useCallback, useState} from 'react';
import {Card, Subheading, Tabs} from '@shopify/polaris';
import LayoutTab from '../common/customizationTabs/layoutTab/layoutTab';
import ProductTab from '../common/customizationTabs/productTab/productTab';
import ButtonsTab from './buttonsTab';

const Customization = (props) => {
  const {state, setState, checkoutOfferType} = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTabIndex(selectedTabIndex),
    [],
  )

  const tabs =
    [
      {
        id: 'layout-1',
        content: 'Layout',
        panelID: 'layout-content-1',
      },
      {
        id: 'product-1',
        content: 'Product',
        panelID: 'product-content-1',
      },
      {
        id: 'button-1',
        content: 'Button',
        panelID: 'button-content-1',
      },
    ];

  return (
    <Card sectioned>
      <Subheading>Customization</Subheading>

      <Tabs tabs={tabs} selected={selectedTabIndex} onSelect={handleTabChange}>
        <div style={{paddingTop: '20px'}}>
          { selectedTabIndex === 0 &&
          <LayoutTab state={state} setState={setState} offerType={checkoutOfferType}/>
          }
          { selectedTabIndex === 1 &&
          <ProductTab state={state} setState={setState} offerType={checkoutOfferType}/>
          }
          { selectedTabIndex === 2 &&
          <ButtonsTab state={state} setState={setState}/>
          }
        </div>
      </Tabs>
    </Card>
  );
};

export default Customization;