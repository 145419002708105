import React, {useEffect, useState, useMemo} from 'react'
import update from 'immutability-helper'
import {
  FormLayout,
  RangeSlider,
  Stack,
  RadioButton,
  TextContainer,
  TextStyle
} from "@shopify/polaris"
import styled from 'styled-components'

const ResponsiveRadio = styled.div`
  max-width: 135px;
`

const LayoutTab = (props) => {
  const {state, setState} = props;
  const [maxProductsNumber, setMaxProductsNumber] = useState(6);

  useEffect(() => {
    const productsNumber =
      state?.shopBrain
        ? state?.productNumber
        : Array.isArray(state.product)
          ? state.product.length
          : 1;

    setMaxProductsNumber(productsNumber > 6 ? 6 : productsNumber);
  }, [state])

  const rangeSuffix = useMemo(() => {
    const suffixNumber = state?.shopBrain
      ? state?.productNumber === 1
        ? 1
        : state.productsPerRow
      : (state.product === null || state.product.length === 1)
        ? 1
        : state.layout === 'row'
          ? state.productsPerRow
          : state.productsToShow;

    return suffixNumber > 6 ? 6 : suffixNumber;
  }, [state]);

  const handleLayoutChange = (_checked, newValue) => setState(update(state, {layout: {$set: newValue}}));

  return (
    <FormLayout>
      {/*<div>*/}
      {/*  <TextContainer spacing="loose">*/}
      {/*    <TextStyle variation="strong">Product layout</TextStyle>*/}
      {/*  </TextContainer>*/}
      {/*  <Stack spacing='extraLoose'>*/}
      {/*    <RadioButton*/}
      {/*      label="Carousel"*/}
      {/*      checked={state.layout === 'carousel'}*/}
      {/*      id="carousel"*/}
      {/*      name="layout"*/}
      {/*      onChange={handleLayoutChange}*/}
      {/*    />*/}
      {/*    <ResponsiveRadio>*/}
      {/*      <RadioButton*/}
      {/*        label="Row"*/}
      {/*        id="row"*/}
      {/*        name="layout"*/}
      {/*        checked={state.layout === 'row'}*/}
      {/*        onChange={handleLayoutChange}*/}
      {/*      />*/}
      {/*    </ResponsiveRadio>*/}
      {/*  </Stack>*/}
      {/*  <Stack>*/}
      {/*    <div style={{padding: '10px 10px 0 0'}}>*/}
      {/*      <img*/}
      {/*        src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Carousel_icon.svg?alt=media&token=5ff293de-fef5-440a-944b-5abc8a4936e6"*/}
      {/*        alt="carousel layout"/>*/}
      {/*    </div>*/}
      {/*    <div style={{padding: '10px 10px 0 7px'}}>*/}
      {/*      <img*/}
      {/*        src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/Row_icon.svg?alt=media&token=ff46b131-139e-4c5b-83d9-a75e7a3123f2"*/}
      {/*        alt="row layout"/>*/}
      {/*    </div>*/}
      {/*  </Stack>*/}
      {/*</div>*/}
      {
        state.layout === 'carousel' ?
          <RangeSlider
            label="Products to show"
            min={1}
            max={maxProductsNumber}
            suffix={<p>{rangeSuffix}</p>}
            value={state.productsToShow}
            onChange={(val) => setState(update(state, {productsToShow: {$set: val}}))}
            output
          />
          :
          <RangeSlider
            label="Products per row"
            min={1}
            max={maxProductsNumber}
            suffix={<p>{rangeSuffix}</p>}
            value={state.productsPerRow}
            onChange={(val) => setState(update(state, {productsPerRow: {$set: val}}))}
            output
          />
      }
    </FormLayout>
  )
};

export default LayoutTab;